import classnames from 'classnames';
import React from 'react';
import { useEffect } from 'react';

export const PAGE_BACKGROUND = {
  AUTO: 'auto',
  DARK_BLUE: 'dark-blue',
  DARK_BLUE_GRADIENT: 'dark-blue-gradient',
  WHITE_CARPET: 'white-carpet',
};

export const PAGE_BACKGROUND_PRISMIC_MAP = {
  Auto: PAGE_BACKGROUND.AUTO,
  'Bleu sombre': PAGE_BACKGROUND.DARK_BLUE,
  'Bleu sombre dégradé': PAGE_BACKGROUND.DARK_BLUE_GRADIENT,
  'Blanc tapisserie': PAGE_BACKGROUND.WHITE_CARPET,
};

export const Page = ({
  background = PAGE_BACKGROUND.AUTO,
  children = null,
  isArticle,
}) => {
  return (
    <div className={classnames('page', `page--background-${background}`)}>
      <div className="page__wrap">
        {children}

        {!isArticle && <div className="page__bottom-margin" />}
      </div>
    </div>
  );
};
