import { isAfter, isValid } from 'date-fns';
import { ModalOverlay, Modal, Dialog, Heading } from 'react-aria-components';
import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import cookies from 'js-cookie';

import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '../Button';
import { CROSS } from '../Icons/icons/cross';

const COOKIE_NAME = 'popup';

const POPUP_DELAY = 5000;

export const Popup = ({ layout }) => {
  const title = prismicH.asText(layout.data.popupTitle);
  const expiration = new Date(layout.data.expiration);

  const hasPopup =
    title !== '' && !(isValid(expiration) && isAfter(new Date(), expiration));

  const cookieValue = (title || 'true').toLowerCase();

  /**
   * @type {[boolean|null, React.Dispatch<any>]}
   */
  const [open, setOpen] = useState(null);

  useEffect(() => {
    if (open === false) {
      cookies.set(COOKIE_NAME, cookieValue, { expires: 365 });
    }
  }, [open]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (cookies.get(COOKIE_NAME) !== cookieValue) {
        const timeoutRef = setTimeout(() => {
          setOpen(cookies.get(COOKIE_NAME) !== cookieValue);
        }, POPUP_DELAY);

        return () => {
          clearTimeout(timeoutRef);
        };
      }
    }
  }, []);

  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return hasPopup ? (
    <ModalOverlay
      className="popup__overlay"
      isOpen={!!open}
      onOpenChange={setOpen}
    >
      <button
        aria-label="Fermer"
        className="popup__overlay__close"
        onClick={close}
      />

      <Modal className="popup__modal">
        <Dialog>
          <div>
            <button
              className="popup__close"
              onClick={close}
              aria-label="Fermer"
            >
              {CROSS}
            </button>

            {title ? (
              <Heading slot="title" className="popup__heading">
                {title}
              </Heading>
            ) : null}

            {prismicH.asText(layout.data.popupDescription) ? (
              <div className="popup__description">
                <PrismicRichText field={layout.data.popupDescription} />
              </div>
            ) : null}

            <div className="popup__cta">
              {prismicH.asText(layout.data.popupButtonText) ? (
                <Button
                  field={layout.data.popupButtonLink}
                  theme="primary"
                  onClick={close}
                >
                  {prismicH.asText(layout.data.popupButtonText)}
                </Button>
              ) : null}

              <Button theme="outline-black" onClick={close}>
                Fermer
              </Button>
            </div>
          </div>
        </Dialog>
      </Modal>
    </ModalOverlay>
  ) : null;
};
