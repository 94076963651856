import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import * as prismicH from '@prismicio/helpers';

import { RichText } from 'prismic-reactjs';

import { MaxWidth } from '../MaxWidth';
import { Button } from '../Button';
import { Link } from '../Link';
import { Menu } from '../Menu';
import { LocaleSwitch } from '../LocaleSwitch';

import { isLink } from '../../helpers/is-link.js';
import config from '../../helpers/config.js';
import { useTransition, animated } from 'react-spring';
import { useRouter } from 'next/router';

const FlashInfo = ({ layout, show, setShow }) => {
  const handleAccept = () => {
    setShow(false);
  };

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (window && document) {
      const handleScroll = () => {
        setScrolled(
          document.scrollingElement && document.scrollingElement.scrollTop > 0
            ? true
            : false
        );
      };

      window.addEventListener('scroll', handleScroll);

      handleScroll();

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <>
      {layout.data.flashInfo && layout.data.flashInfo != '' ? (
        <div
          className={classnames('flash-info', {
            'flash-info--show': show,
            'flash-info--scrolled': scrolled,
          })}
        >
          <div className="flash-info__inner">
            <img
              src="/icons/flash-info.png"
              srcSet="/icons/flash-info.svg"
              alt="Flash info"
            />

            <div className="flash-info__content">
              <RichText render={layout.data.flashInfo} />
            </div>
          </div>

          <button onClick={handleAccept} aria-label="Fermer le flash info">
            <img
              src="/icons/close.png"
              srcSet="/icons/close.svg"
              alt="Fermer"
            />
          </button>
        </div>
      ) : null}
    </>
  );
};
const HeaderNavItem = ({ primary, items }) => {
  const [hover, setHover] = useState(false);
  const transitions = useTransition(hover, {
    from: { opacity: 0, transform: 'rotateX(-35deg)' },
    enter: { opacity: 1, transform: 'rotateX(0deg)' },
    leave: { opacity: 0, transform: 'rotateX(-35deg)' },
    config: { tension: 280, friction: 20, duration: 100 },
  });
  return (
    <>
      <li
        key={primary.linkText}
        className={classnames('header__nav__item', {
          'header__nav__item--hover': hover,
          'header__nav__item--has-sublist':
            items[0] && items[0].secondaryLink.link_type != 'Any',
        })}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Link field={primary.link}>
          <span>{primary.linkText}</span>
        </Link>

        {transitions((style, item) =>
          item ? (
            <animated.div
              style={style}
              className={classnames('header__nav__sublist__wrap', {})}
            >
              <ul className="header__nav__sublist">
                {items.map((item) => {
                  return (
                    <li
                      key={prismicH.asText(item.secondaryLinkText)}
                      className={classnames(
                        'header__nav__subitem',
                        `header__nav__subitem__${item.color}`
                      )}
                    >
                      <Link field={item.secondaryLink}>
                        <span>{prismicH.asText(item.secondaryLinkText)}</span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </animated.div>
          ) : null
        )}
      </li>
    </>
  );
};

export const Header = ({ menu, locale, layout, flashInfo, setFlashInfo }) => {
  const [closing, setClosing] = useState(false);
  const [open, setOpen] = useState(false);
  const [touchDown, setTouchDown] = useState(false);

  const router = useRouter();

  const toggleMenu = () => {
    if (!open) {
      setClosing(false);
      setOpen(true);
      document.body.style.overflow = 'hidden';
      document.body.classList.add('overlay--open');
    } else {
      setClosing(true);
      setOpen(false);
      document.body.style.overflow = 'visible';
      document.body.classList.remove('overlay--open');
    }
  };

  const leftPart = menu.data.slices
    .slice(0, Math.round(menu.data.slices.length / 2))
    .map((slice) => {
      const linkText = slice.primary.linkText[0]?.text;
      return {
        ...slice,
        primary: {
          ...slice.primary,
          linkText: linkText,
        },
      };
    });

  const rightPart = menu.data.slices
    .slice(Math.round(menu.data.slices.length / 2), menu.data.slices.length)
    .map((slice) => ({
      ...slice,
      primary: {
        ...slice.primary,
        linkText: slice.primary.linkText.map((textItem) => textItem.text),
      },
    }));

  if (
    menu.data.ctas.length > 0 &&
    router.asPath.split('/').includes('team-building-paris')
  ) {
    menu.data.ctas[menu.data.ctas.length - 1] = {
      label: menu.data.last_tb_cta_title,
      link: menu.data.last_tb_cta_link,
      color: menu.data.last_tb_cta_color,
    };
  }

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (window && document) {
      const handleScroll = () => {
        setScrolled(
          document.scrollingElement && document.scrollingElement.scrollTop > 0
            ? true
            : false
        );
      };

      window.addEventListener('scroll', handleScroll);

      handleScroll();

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <>
      <header
        className={classnames('header', {
          'header--scrolled': scrolled,
        })}
      >
        {menu.data.overMenu && menu.data.overMenu.length > 0 ? (
          <div className="header__over-menu">
            <MaxWidth>
              <ul className="header__over-menu__list">
                {menu.data.overMenu.map((item) => {
                  return (
                    <React.Fragment key={prismicH.asText(item.title)}>
                      <li
                        className="header__over-menu__list__item"
                        key={prismicH.asText(item.title)}
                      >
                        <Link field={item.link}>
                          <span>{prismicH.asText(item.title)}</span>
                        </Link>
                      </li>

                      <li className="header__over-menu__list__item header__over-menu__list__item-separator"></li>
                    </React.Fragment>
                  );
                })}

                {prismicH.asText(menu.data.OverMenuAdress) ? (
                  <li className="header__over-menu__list__item header__over-menu__list__item-adress">
                    <Link field={menu.data.OverMenuAdressLink}>
                      <span>{prismicH.asText(menu.data.OverMenuAdress)}</span>
                    </Link>
                  </li>
                ) : null}
              </ul>
            </MaxWidth>
          </div>
        ) : null}

        <div className="header__gradient"></div>
        {layout.data.flashInfo && prismicH.asText(layout.data.flashInfo) ? (
          <FlashInfo layout={layout} show={flashInfo} setShow={setFlashInfo} />
        ) : null}

        <MaxWidth>
          <div className="header__grid">
            {isLink(menu.data.mobileCtaLink) &&
            prismicH.asText(menu.data.mobileCtaLabel) ? (
              <div className="header__mobile-cta">
                <Button
                  field={menu.data.mobileCtaLink}
                  height="small"
                  theme="primary"
                >
                  {prismicH.asText(menu.data.mobileCtaLabel)}
                </Button>
              </div>
            ) : null}

            <div className="header__part header__part--left">
              <LocaleSwitch locale={locale} />
              <ul className="header__nav">
                {leftPart.map((slice) => {
                  return <HeaderNavItem key={slice.id} {...slice} />;
                })}
              </ul>
            </div>

            <Link className="header__logo" href={locale.current.pathPrefix}>
              <img
                className="header__logo__light"
                src="/logo.svg"
                width="210"
                height="48"
                alt={config.siteName}
                draggable={false}
              />
            </Link>

            <div className="header__part header__part--right">
              <ul className="header__nav">
                {rightPart.map((slice) => {
                  return <HeaderNavItem key={slice.id} {...slice} />;
                })}
              </ul>

              <ul className="header__ctas">
                {menu.data.ctas.map((item) => {
                  return (
                    <li key={prismicH.asText(item.label)}>
                      <Button field={item.link} height="small" theme="primary">
                        {prismicH.asText(item.label)}
                      </Button>
                    </li>
                  );
                })}
              </ul>
            </div>

            <button
              className={classnames('header__burger', {
                'header__burger--touch-down': touchDown,
              })}
              onClick={toggleMenu}
              onTouchStart={() => setTouchDown(true)}
              onTouchMove={() => setTouchDown(false)}
              onTouchEnd={() => setTouchDown(false)}
            >
              <div />
              <span className="visually-hidden">Open menu</span>
            </button>
          </div>
        </MaxWidth>
      </header>

      <Menu
        menu={menu}
        open={open}
        closing={closing}
        toggleMenu={toggleMenu}
        locale={locale}
      />
    </>
  );
};
