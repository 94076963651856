import React, { useState, useEffect } from 'react';
import Head from 'next/head';

import { Header } from '../Header';
import { Main } from '../Main';
import { Footer } from '../Footer';
import { StickySocial } from '../StickySocial';
import { Popup } from '../Popup';

import { LOCALES } from '../../config/index.js';

import { normalizeSlug } from '../../helpers/normalize-slug';

export const Layout = ({
  children,
  menu,
  layout,
  locale,
  theme,
  isArticle,
  displaySkyline = true,
}) => {
  const [flashInfo, setFlashInfo] = useState(true);

  useEffect(() => {
    if (document.body) {
      setTimeout(function () {
        document.documentElement.className += ' js-can-use-transitions';
      }, 200);
    }
  }, []);

  useEffect(() => {
    document.body.classList.add(`theme-${theme}`);
    return () => {
      document.body.classList.remove(`theme-${theme}`);
    };
  }, [theme]);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,viewport-fit=cover"
        />

        {locale.alternate_languages.map((alternative) => {
          const alternativeLocale = LOCALES.find(
            (item) => item.locale === alternative.lang
          );

          if (alternativeLocale && alternativeLocale.index) {
            return (
              <link
                key={alternative.lang}
                rel="alternate"
                hrefLang={alternativeLocale.hrefLang}
                href={`https://${process.env.HOST}${normalizeSlug(
                  alternative.slug
                )}`}
              />
            );
          }
        })}

        <link
          key={locale.current.locale}
          rel="alternate"
          hrefLang={locale.current.hrefLang}
          href={`https://${process.env.HOST}${normalizeSlug(locale.slug)}`}
        />

        <link rel="icon" href="/favicon.ico" sizes="any" />
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/site.webmanifest" />

        <meta name="theme-color" content="#0b1a2b" />

        {process.env.INDEX !== '1' ? (
          <meta name="robots" content="noindex,nofollow" />
        ) : null}
      </Head>

      <Header
        menu={menu}
        locale={locale}
        layout={layout}
        flashInfo={flashInfo}
        setFlashInfo={setFlashInfo}
      />

      <Popup layout={layout} />

      <Main flashInfo={flashInfo}>{children}</Main>

      <StickySocial layout={layout} />

      <Footer
        layout={layout}
        isArticle={isArticle}
        displaySkyline={displaySkyline}
      />
    </>
  );
};
