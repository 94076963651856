import React, { useState, useMemo, useCallback } from 'react';
import * as prismicH from '@prismicio/helpers';
import AnimateHeight from 'react-animate-height';
import classnames from 'classnames';

import { Link } from '../Link';

const ICONS = {
  chevron: (
    <svg
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="#37C4E6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.65617 5.30478C4.57611 5.40486 4.42389 5.40486 4.34383 5.30478L0.759951 0.824939C0.655189 0.693987 0.748424 0.5 0.916125 0.5L8.08387 0.5C8.25158 0.5 8.34481 0.693986 8.24005 0.824939L4.65617 5.30478Z"
        fill="#37C4E6"
      />
    </svg>
  ),
};

const subListItem = ({ hasChild, slice, index, toggleMenu }) => {
  const [height, setHeight] = useState(0);

  const filteredItems = useMemo(() => {
    return (slice.items || []).filter((item) => {
      return item?.secondaryLink?.link_type !== 'Any';
    });
  }, [slice.items]);

  const toggle = useCallback(() => {
    setHeight((current) => {
      return current === 0 ? 'auto' : 0;
    });
  }, [setHeight]);

  return (
    <li
      className={classnames('menu__lists__item', {
        'menu__lists__item--has-sublist': hasChild,
      })}
    >
      {filteredItems.length > 0 ? (
        <>
          <button
            aria-expanded={height !== 0}
            aria-controls={`sublist-${index}`}
            onClick={toggle}
          >
            {prismicH.asText(slice.primary.linkText)}
            {ICONS.chevron}
          </button>

          <AnimateHeight
            id={`sublist-${index}`}
            className="menu__lists__item__sublist"
            duration={500}
            height={height}
          >
            {filteredItems.map((item) => (
              <div key={prismicH.asText(item.secondaryLinkText)}>
                <Link
                  field={item.secondaryLink}
                  className="menu__lists__subitem"
                  onClick={toggleMenu}
                >
                  {prismicH.asText(item.secondaryLinkText)}
                </Link>
              </div>
            ))}
          </AnimateHeight>
        </>
      ) : (
        <Link field={slice.primary.link} onClick={toggleMenu}>
          {prismicH.asText(slice.primary.linkText)}
        </Link>
      )}
    </li>
  );
};

export default subListItem;
