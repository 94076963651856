import classnames from 'classnames';
import React, { useState } from 'react';
import { Button } from '../Button';
import * as prismicH from '@prismicio/helpers';

import Item from './item';
import { Link } from '../Link';
import Image from 'next/image';

export const Menu = ({ menu, open, closing, toggleMenu, locale }) => {
  const [touchDown, setTouchDown] = useState(false);

  return (
    <nav
      className={classnames('menu', {
        'menu--open': open,
        'menu--animate-close': closing,
      })}
    >
      <button className="menu__close-zone" onClick={toggleMenu} />
      <div className="menu__header">
        <Link className="menu__logo" href="/" onClick={toggleMenu}>
          <Image src="/logo-black.svg" alt="logo" width={133} height={25} />
        </Link>

        <button
          className={classnames('menu__burger', {
            'menu__burger--touch-down': touchDown,
          })}
          onClick={toggleMenu}
          onTouchStart={() => setTouchDown(true)}
          onTouchMove={() => setTouchDown(false)}
          onTouchEnd={() => setTouchDown(false)}
        >
          <div />
        </button>
      </div>

      <div className="menu__lists">
        <ul>
          {menu.data.slices.map((slice, index) => {
            const hasChild =
              slice.items[0] && slice.items[0].secondaryLink.link_type != 'Any';

            return (
              <Item
                key={slice.id}
                hasChild={hasChild}
                slice={slice}
                index={index}
                toggleMenu={toggleMenu}
              />
            );
          })}

          {menu.data.overMenu.map((item) => {
            return (
              <li
                key={prismicH.asText(item.label)}
                className="menu__lists__item"
              >
                <Link field={item.link} onClick={toggleMenu}>
                  {prismicH.asText(item.title)}
                </Link>
              </li>
            );
          })}

          <div className="menu__ctas">
            {menu.data.ctas.map((item) => (
              <li key={prismicH.asText(item.label)}>
                <Button
                  field={item.link}
                  height="mobile-cta"
                  onClick={toggleMenu}
                >
                  {prismicH.asText(item.label)}
                </Button>
              </li>
            ))}
          </div>
        </ul>
      </div>
    </nav>
  );
};
